import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import RosaSection from "../components/elements/BeigeSection"

function FAQ() {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<RosaSection>
				<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
					<div className="item-center grid w-full grid-cols-1 justify-items-center">
						<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
					</div>
				</header>
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-blue ">
					<h2 className="h2 text-red mb-16 pt-4 text-center text-2xl">FAQ </h2>
					<AccordionItem headline="Wie lange dauert die Aktion?" num="1.">
						<p className="text-red">
							Der Teilnahmezeitraum ist der 02.01.2024 bis einschließlich 31.03.2024 23:59 Uhr deutscher Zeit. Danach ist eine Teilnahme an unserem Gewinnspiel nicht mehr möglich.
						</p>
					</AccordionItem>
					<AccordionItem headline="Welche Produkte nehmen an der Aktion teil?" num="2.">
						<>
							<p className="text-red mb-6">
								Aktionsprodukte sind die folgenden Produkte der Marke "HYDRO", die von den teilnehmenden Händlern angeboten werden. Dazu gehören die folgenden Produkte:{" "}
							</p>
							<ul className="mb-4 list-outside list-disc">
								<li>Hydro 5 Rasierapparat in Probiergröße (GTIN: 4027800979905)</li>
								<li>Hydro 3 Rasierapparat in Probiergröße (GTIN: 4027800479832)</li>
								<li>Hydro 5 Klinge 4+1 (GTIN: 4027800892266)</li>
								<li>Hydro 3 Klinge 4+1 (GTIN: 4027800402038)</li>
							</ul>
						</>
					</AccordionItem>
					<AccordionItem headline="Wie nehme ich teil?" num="3.">
						<p className="text-red mb-6">
							Um am Gewinnspiel teilzunehmen, ist der Kauf eines Aktionsproduktes in der Filiale eines teilnehmenden Händlers im Zeitraum 02.01.2024 bis 31.03.2024 erforderlich.
						</p>
						<p className="text-red mb-6">
							Der Teilnehmer muss seinen vollständigen Rechnungsbeleg auf der Aktionsseite{" "}
							<a target="_blank" className="underline" href="https://www.gewinnspiel-hydro.de">
								www.gewinnspiel-hydro.de
							</a>{" "}
							hochladen, das Teilnahmeformular vollständig ausfüllen und den Button „Jetzt teilnehmen!“ anklicken.
						</p>
						<p className="text-red mb-6">
							Die Ermittlung des Gewinners erfolgt innerhalb von zwei Wochen nach Aktionsende per Zufallsziehung aus allen gültigen Teilnahmen. Der Gewinner wird per E-Mail über
							seinen Gewinn informiert und muss formlos (per E-Mail) die Gewinnannahme bestätigen. Nimmt der Teilnehmer den Gewinn nicht an oder meldet sich nach zweimaliger
							Aufforderung innerhalb eines Monats nicht zurück, so verfällt der Gewinnanspruch und ein neuer Gewinner wird ermittelt.
						</p>
						<p className="text-red mb-6">Solltest du nach Ablauf des o.a. Zeitraums keine E-Mail von uns erhalten haben, bist du leider nicht unter den Gewinnern.</p>
					</AccordionItem>
					<AccordionItem headline="Kommen ggf. Kosten auf mich zu?" num="4.">
						<>
							<p className="text-red mb-6">
								Für die Teilnahme sind ein Internetzugang sowie der Kauf eines Aktionsprodukts beim teilnehmenden Händler erforderlich. Darüber hinaus entstehen keine weiteren
								Kosten.
							</p>
						</>
					</AccordionItem>
					<AccordionItem headline="Was gibt es zu gewinnen?" num="5.">
						<p className="text-red mb-6">
							Unter allen Teilnehmern werden zwanzig (20) Sony Playstation® 5 Disk Edition verlost. Zehn (10) Sony Playstation® 5 sind exklusiv für Kunden des Händlers Dirk
							Rossmann GmbH reserviert. Der Wert eines jeden Preises beträgt 549,99€ UVP.
						</p>
						<p className="text-red">
							Die Teilnahme an der Verlosung ist ausschließlich online über die Aktionswebsite{" "}
							<a target="_blank" className="underline" href="https://www.gewinnspiel-hydro.de">
								www.gewinnspiel-hydro.de
							</a>{" "}
							möglich. Teilnahmeschluss ist der 31.03.2024 um 23:59 Uhr deutscher Zeit.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wer kann am Gewinnspiel teilnehmen?" num="6.">
						<p className="text-red">
							Teilnahmeberechtigt sind alle Personen mit Wohnsitz in Deutschland, die mindestens 18 Jahre alt sind. Von der Teilnahme ausgeschlossen sind Mitarbeiter der Wilkinson
							Sword GmbH. Der Versand der Gewinne ins Ausland ist nicht möglich.
						</p>
					</AccordionItem>
					<AccordionItem headline="Kann ich aus dem Ausland teilnehmen?" num="7.">
						<p className="text-red">
							Nein, eine Teilnahme aus dem Ausland ist nicht möglich. Teilnahmeberechtigt sind alle Personen mit Wohnsitz in Deutschland, die mindestens 18 Jahre alt sind.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie oft kann ich an der Aktion teilnehmen?" num="8.">
						<p className="text-red mb-6">
							Eine Mehrfachteilnahme ist möglich, d. h. jeder Teilnehmer kann – jeweils mit neuem Rechnungsbeleg und gekauften Aktionsprodukten – auch mehrmals am Gewinnspiel
							teilnehmen.
						</p>
						<p className="text-red">
							Jeder Rechnungsbeleg darf nur einmal zur Teilnahme am Gewinnspiel verwendet werden und stellt somit nur eine Gewinnchance dar, auch wenn mehrere Produkte gekauft
							wurden.
						</p>
					</AccordionItem>
				</div>
			</RosaSection>
			<div className="text-red py-10 pt-5 text-center text-xl">
				<button onClick={() => navigate(-1)} className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-blue">
					Zurück
				</button>
			</div>
		</DocumentMeta>
	)
}
export default FAQ
