import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<RosaSection>
				<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
					<div className="item-center grid w-full grid-cols-1 justify-items-center">
						<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
					</div>
				</header>
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-blue">
					<h2 className="h2 text-red mt-12 text-2xl font-bold">Impressum</h2>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">Wilkinson Sword GmbH </h2>
					<p className=" mb-4">
						Schützenstraße 110
						<br />
						42659 Solingen
						<br />
						Germany
					</p>
					<p className=" mb-4">
						Telefon: +49 212 405 0 (Mo-Fr: 09-15 Uhr)
						<br />
						E-Mail: <a href="mailto:info@wilkinson.de">info@wilkinson.de</a>
						<br />
						Internet: www.wilkinsonsword.com
					</p>
					<p className=" mb-4">
						Wilkinson Sword GmbH Geschäftsführer: <br />
						Thomas Lummerich, Jochen Schmitz, John Norman Hill, LaTanya Langley
					</p>
					<p className=" mb-4">
						Handelsregister: HRB 16753 Registergericht: Amtsgericht Wuppertal
						<br />
						Ust-ID gemäß §27a Umsatzsteuergesetz: DE813978227
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">HAFTUNGSAUSSCHLUSS</h2>
					<p className=" mb-4">
						Die Firma Wilkinson Sword GmbH ist bemüht sein Online-Angebot stets aktuell und inhaltlich richtig sowie vollständig zu gestalten. Dennoch ist das Auftreten von Fehlern
						nicht völlig auszuschließen. Die Wilkinson Sword GmbH übernimmt, soweit nicht gesetzlich, z.B. durch die Preisangabenverordnung, vorgegeben, keine Haftung für die
						Aktualität, inhaltliche Richtigkeit sowie die Vollständigkeit der in ihrem Webangebot eingestellten Informationen. Die Wilkinson Sword GmbH ist nicht für die Inhalte
						fremder Seiten verantwortlich, die Sie über einen Link erreichen. Sie übernimmt keine Gewähr für die Richtigkeit und Vollständigkeit der Inhalte und Informationen, die
						Sie über die von Wilkinson Sword angebotenen Links zu anderen Anbietern erhalten. Dies gilt auch für Links auf Seiten von Anbietern und Unternehmen, die Wilkinson Sword
						nahestehen oder die mit Wilkinson Sword verbunden sind.
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">URHEBERRECHTSERKLÄRUNG</h2>
					<p className=" mb-4">
						Inhalt und Struktur des Wilkinson Sword Online-Shops sowie der sonstigen von Wilkinson Sword betriebenen Webseiten sind urheberrechtlich geschützt. Alle Texte, Bilder,
						Grafiken, Ton-, Video- und Animationsdateien unterliegen dem Urheberrecht, dem Markenrecht und anderen Gesetzen zum Schutz geistigen Eigentums. Einige Internet-Seiten
						enthalten auch Bilder, Grafiken, Ton-, Video- und Animationsdateien, die dem Urheberrecht, dem Markenrecht und den anderen Gesetzen zum Schutz geistigen Eigentums
						derjenigen unterliegen, die diese zur Verfügung gestellt haben. Eine Kopie oder sonstige Nutzung für andere als private Zwecke oder zur Weitergabe, anderweitigen
						Veröffentlichung, Vervielfältigung und Verbreitung, ob in originärer oder veränderter Form und in jedwedem Medium, oder eine solche Verwendung auf anderen Webseiten ist
						ohne vorherige ausdrückliche schriftliche Zustimmung der Wilkinson Sword GmbH nicht zulässig.
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">LINKS</h2>
					<p className=" mb-4">
						<a href="https://edgewell.com/pages/policies-statements" target="_blank" rel="noreferrer">
							Edgewell Policies
						</a>
						<br />
						<a href="https://edgewell.com/pages/suppliers" target="_blank" rel="noreferrer">
							Edgewell Suppliers
						</a>
					</p>
				</div>
			</RosaSection>
			<div className="text-red bg-tuerkis py-10 pt-5 text-center text-xl">
				<button onClick={() => navigate(-1)} className="btn btn-hero mt-8 rounded bg-blue px-12 py-4 text-white">
					Zurück
				</button>
			</div>
		</DocumentMeta>
	)
}
export default Imprint
