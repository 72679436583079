import React from "react"
import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section>
			<div className="mx-auto mb-3 grid max-w-[800px] grid-cols-1 items-start gap-2 lg:grid-cols-[1fr_1fr]">
				<ElemInput
					label={"Vorname*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte Vornamen eingeben."}
				/>
				<ElemInput
					label={"Name*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte Nachnamen eingeben."}
				/>
			</div>
			<div className="mx-auto mb-3 grid max-w-[800px] grid-cols-1 items-start gap-5 lg:grid-cols-[1fr_3fr]">
				<ElemInput
					className="col-span-2 lg:col-span-1"
					label={"Geburtstag* (min. 18 J.)"}
					props={{
						type: "date",
						name: "birthday",
						value: globalState.birthday,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "birthday"),
					}}
					hasError={validation["invalid_birthday"] ? true : false}
					errMsg={validation["invalid_birthday_errMsg"] || "Bitte angeben (min. 18 Jahre)."}
				/>
				<ElemInput
					className="col-span-2 lg:col-span-1"
					label={"E-Mail-Adresse*"}
					props={{
						type: "email",
						name: "email",
						value: globalState.email,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "email"),
					}}
					hasError={validation["invalid_email"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte E-Mail-Adresse eingeben."}
				/>
			</div>
			<div className="mx-auto mb-3 mb-12 grid max-w-[800px] items-start">
				<p className="mb-2 text-white">Hier habe ich meine Hydro Produkte gekauft*:</p>
				<ElemSelect
					className="col-span-2 lg:col-span-1"
					label={""}
					props={{
						name: "shop",
						value: globalState.shop,
						required: "required",
						onChange: e => formElementChanged(e, "shop"),
					}}
					hasError={validation["invalid_shop"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte auswählen."}
				>
					<option value="">Bitte auswählen</option>
					<option value="A">Combi</option>
					<option value="B">Edeka</option>
					<option value="C">Famila</option>
					<option value="D">Globus</option>
					<option value="E">Kaufland</option>
					<option value="F">Markant</option>
					<option value="G">Müller</option>
					<option value="H">Penny</option>
					<option value="I">Rossmann</option>
					<option value="J">V-Markt</option>
				</ElemSelect>
			</div>
		</section>
	)
}

export default Person
