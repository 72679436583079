import { Link } from "react-router-dom"

const MobileMenu = ({ isPromoOver, setShowMenu, scrollToAnchor }) => {
	return (
		<div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 bg-green text-center items-center ">
			<div className="flex items-center align-middle relative h-[100vh]">
				<img
					src={process.env.PUBLIC_URL + "/images/icon-close.png"}
					alt="Menu"
					loading="lazy"
					width="83"
					height="73"
					className=" w-[83px] cursor-pointer absolute top-4 right-4"
					onClick={() => setShowMenu(false)}
				/>
				<ul className="mx-auto">
					{!isPromoOver && (
						<>
							<li className="my-4">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("sogehts")
										setShowMenu(false)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "header", link: "sogehts" })
										} catch (e) {}
									}}
									className="header-item"
								>
									So geht's
								</Link>
							</li>
							<li className="my-4">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("mitmachen")
										setShowMenu(false)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "header", link: "jetztmitmachen" })
										} catch (e) {}
									}}
									className="header-item"
								>
									Jetzt mitmachen
								</Link>
							</li>
						</>
					)}
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Teilnahmebedingungen
						</Link>
					</li>
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "faq" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							FAQ
						</Link>
					</li>
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Kontakt
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default MobileMenu
