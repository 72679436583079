import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<RosaSection>
				<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
					<div className="item-center grid w-full grid-cols-1 justify-items-center">
						<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
					</div>
					<div className="grid h-[380px] grid-cols-[20%_80%] md:h-[400px] lg:h-[620px]">
						<div className="pl-12">
							<img
								src={process.env.PUBLIC_URL + "/images/knossi/JensKnosallaFull.png"}
								className="absolute z-[100] max-h-[450px] -translate-y-[30px] -translate-x-[25px] md:max-h-[80%] lg:max-h-[80%] lg:-translate-y-[80px] lg:translate-x-[5px] xl:max-h-[230%] "
							></img>
						</div>
						<div className=" mx-auto mt-12 max-w-[275px] pl-20 text-center lg:mt-24 lg:max-w-[830px] lg:pr-6 lg:pl-44">
							{" "}
							<h2 className="mb-4 text-[18px] font-bold uppercase leading-[18px] text-white lg:mb-8 lg:text-[52px] lg:leading-[51px]">
								Vielen Dank für die Teilnahme an unserem GEWINNSPIEL!
							</h2>
							<p className="mb-4 text-[12px] text-white lg:text-[18px]">
								Deine Daten wurden erfolgreich übermittelt. Du erhältst per E-Mail eine Eingangsbestätigung. Anschließend werden wir die Einhaltung der Teilnahmevoraussetzungen
								prüfen.
							</p>
							<p className="mb-4 text-[12px] text-white lg:text-[18px]">
								Wenn du dich für den Newsletter angemeldet hast, bestätige bitte den Link in der Bestätigungsmail zum Abschluss der Anmeldung.
							</p>
							<p className="mb-4 text-[12px] text-white lg:text-[18px]">
								Viele Grüße <br />
								Dein Wilkinson Team
							</p>
						</div>
					</div>
				</header>
				<div className="text-red relative z-0 px-4 pt-0 pb-16 text-center text-xl"></div>
			</RosaSection>
		</>
	)
}
export default Confirm
