interface PreFillFormProps {
	formElementChanged: Function
}
const PreFillForm: React.FC<PreFillFormProps> = ({ formElementChanged }) => {
	return (
		<>
			{process.env.NODE_ENV === "development" && (
				<span
					className="btn btn-default cursor-pointer"
					onClick={() => {
						formElementChanged({ target: { value: "firstname" } }, "firstname")
						formElementChanged({ target: { value: "lastname" } }, "surname")
						formElementChanged({ target: { value: "ff@fake.de" } }, "email")
						formElementChanged({ target: { value: "1970-01-01" } }, "birthday")
						formElementChanged({ target: { value: "a" } }, "shop")
					}}
				>
					+++ fill with testdata ++++
				</span>
			)}
		</>
	)
}

export default PreFillForm
