const domains = {
	"www.gewinnspiel-hydro.de": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},
	"gewinnspiel-hydro.de": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},

	"prod.fe.wilkinson-hydro.campaign-loyalty.com": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},

	"int.fe.wilkinson-hydro.campaign-loyalty.com": {
		env: "stage",
		apibase: "/inte/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},
	localhost: {
		env: "local",
		apibase: "/inte/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},
	"localhost:3000": {
		env: "local",
		apibase: "/inte/",
		recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "", recaptchakey: "6LcZUvwoAAAAALuFIWuuUeR1cOqEm4B1XVQjVOVR" }
}
