import { useAutoAnimate } from "@formkit/auto-animate/react"
import React from "react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<select
				className={
					"placeholder-transparent text-red text-bold peer w-full appearance-none rounded bg-white bg-[url('/src/bgimages/icon-dropdown.png')] bg-[length:20px_25px] bg-[center_left_1rem] bg-no-repeat py-3 px-4 pl-12 text-pink shadow-inner outline-0 " +
					(hasError ? "  js-formerror " : "")
				}
				{...props}
			>
				{children}
			</select>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg pt-2 font-bold text-pink">{errMsg}</div>}</div>
			<span className={"text-bold  block pt-2 pl-2" + (hasError ? "  text-white" : " text-white ")}>{label}</span>
		</label>
	)
}

export default ElemSelect
