const RosaSection = ({ children }) => {
	return (
		<section>
			<div className="wrap relative" style={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/sectionMiddle.png")' }}>
				{children}
			</div>
		</section>
	)
}

export default RosaSection
