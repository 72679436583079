import { useContext } from "react"
import BeigeSection from "../elements/BeigeSection"
import ReceiptUpload from "../receipt/ReceiptUpload"
import { Context } from "../../store/store"

const Sogehts = () => {
	const { dispatch } = useContext(Context)

	return (
		<BeigeSection>
			<div className="border-blue-after  relative grid grid-cols-1 bg-blue md:grid-cols-[30%_70%] lg:grid-cols-[10%_80%] xl:grid-cols-[20%_80%]">
				<div></div>
				<img className="absolute -right-[20px] top-[180px] z-[200] hidden h-[167px] w-[47px] lg:block" src={process.env.PUBLIC_URL + "/images/blue-border-left.png"} />

				<div className="h-[410px] lg:h-[675px]">
					<p className="mb-6 pt-12 text-center font-Gotham text-[28px] font-bold  uppercase text-tuerkis lg:mb-0 lg:mt-12 lg:pt-12 lg:text-[62px] xl:pt-0">So einfach geht's:</p>
					<img
						src={process.env.PUBLIC_URL + "/images/knossi/arrow_green.png"}
						className="absolute right-[66px] top-[55px] mt-4 hidden w-[100px]  -rotate-[50deg] lg:top-[100px] lg:w-[92px] xl:right-[274px] xl:block"
					/>
					<div className="sogehts mb-0 text-[40px] font-bold lg:mt-12">
						<p className="mb:12 mr-0 bg-white font-Gotham font-bold text-blue">1</p>
						<span className="mb-6 text-center font-Gotham text-[24px] uppercase text-white lg:text-[24px]">Kaufe ein Wilkinson Hydro Aktionsprodukt</span>
					</div>
					<div className="relative z-[300] md:translate-x-[60px] lg:translate-x-[100px]">
						<img
							className="absolute z-[300] mt-12 mb-8 max-w-[80%] translate-x-[15%] text-center md:max-w-[75%]  md:translate-x-[5%] md:-translate-y-[40px] lg:mt-12 lg:translate-x-[120px]"
							src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_produkte.png"}
						/>
					</div>
				</div>
			</div>
			<div className="js-sogehts relative z-0 mx-auto -mt-[1px] grid max-w-[1000px] grid-cols-1 flex-col items-center bg-tuerkis px-6 pb-12 pt-[40px] md:pt-[120px] lg:grid-cols-[40%_60%] lg:pt-[100px]">
				<div className="arrow-white relative mt-24 mb-12 grid items-center justify-items-center text-center text-[40px]  font-bold text-tuerkis lg:mt-24">
					<img src={process.env.PUBLIC_URL + "/images/knossi/bon-upload.png"} className="mb-4 hidden max-w-[150px] lg:mb-0 lg:block lg:max-w-[282px]" alt="" />
				</div>
				<div>
					<div className="upload sogehts items-center bg-tuerkis sm:flex-row lg:mt-36 xl:mt-56 ">
						<p className="bg-white font-Gotham text-blue">2</p>

						<span className="mb-6 text-center font-Gotham text-[24px] uppercase text-white lg:text-[24px]">Lade hier deinen Rechnungsbeleg hoch</span>

						<div className="mt-2 mb-2 grid items-center justify-items-center lg:mt-36 lg:hidden">
							<img src={process.env.PUBLIC_URL + "/images/knossi/bon-upload.png"} className="arrow-white mb-4 max-w-[100px] lg:mb-0 lg:max-w-[282px]" alt="" />
						</div>
					</div>
					<div>
						<ReceiptUpload dispatch={dispatch} />
					</div>
				</div>
			</div>
		</BeigeSection>
	)
}

export default Sogehts
