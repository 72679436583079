import { useEffect, useState } from "react"
import RosaSection from "../components/elements/BeigeSection"
import { Link, useSearchParams } from "react-router-dom"
import axios from "axios"
import { getEnvConfig } from "../env"

function NewsletterConfirm() {
	const env = getEnvConfig()
	const [searchParams] = useSearchParams()
	const confirmationid = searchParams.get("hash")
	const [confirmSuccess, setConfirmSuccess] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}
	const confirmNewsletter = () => {
		axios
			.post(env.apibase + "confirmNewsletter.php?hash=" + confirmationid)
			.then(res => {
				if (res.data.doubleOptInConfirmed) {
					setConfirmSuccess(true)
				}
			})
			.catch(error => {
				setErrorMessage("Deine Registrierung war bereits erfolgreich")
				setConfirmSuccess(false)
			})
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<RosaSection>
			<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
				<div className="item-center grid w-full grid-cols-1 justify-items-center">
					<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
				</div>
				<div className="grid h-[380px] grid-cols-[20%_80%] md:h-[400px] lg:h-[620px]">
					<div className="pl-12">
						<img
							src={process.env.PUBLIC_URL + "/images/knossi/JensKnosallaFull.png"}
							className="absolute z-[100] max-h-[450px] -translate-y-[30px] -translate-x-[25px] md:max-h-[80%] lg:max-h-[80%] lg:-translate-y-[80px] lg:translate-x-[5px] xl:max-h-[230%] "
						></img>
					</div>
					<div className=" mx-auto mt-24 max-w-[275px] pl-20 text-center lg:max-w-[830px] lg:pr-6 lg:pl-44">
						{confirmSuccess ? (
							<>
								<h2 className="mb-8 text-[18px] uppercase text-white lg:text-[42px]">Vielen Dank, Du hast dich erfolgreich für den Newsletter angemeldet.</h2>
								<div className="text-red bg-tuerkis py-10 pt-5 text-center text-xl">
									<Link to="/" className="btn btn-hero mt-8 rounded bg-blue px-12 py-4 text-white">
										Zurück
									</Link>
								</div>
								<p className="mb-4 text-[12px] text-white lg:text-[24px]">
									Viele Grüße <br />
									Dein Wilkinson Team
								</p>
							</>
						) : (
							<>
								<h2 className="mb-8 text-[18px] font-bold uppercase text-white lg:text-[42px]">Fast geschafft!</h2>
								<p className="mb-8 py-2 text-[11px] text-white lg:px-12 lg:text-[24px]">
									Klicke einfach auf den untenstehenden Button, um die Registrierung für unseren Wilkinson Sword Newsletter abzuschließen.
								</p>

								{errorMessage && <p className="mb-6  bg-pink py-2 text-[11px] text-white text-white lg:px-12 lg:text-[16px]">{errorMessage}</p>}
								<a className="btn btn-hero mt-8 cursor-pointer rounded bg-blue px-8 py-4 text-[12px] text-white lg:px-12 lg:text-[16px]" onClick={() => confirmNewsletter()}>
									Newsletter bestätigen
								</a>
							</>
						)}
					</div>
				</div>
			</header>

			<div className="text-red relative z-0 px-4 pt-0 pb-16 text-center text-xl"></div>
		</RosaSection>
	)
}
export default NewsletterConfirm
