interface AlertProps {
	children?: any
	className?: any
}

const Alert: React.FC<AlertProps> = ({ children, className }) => {
	return <section className={"bg-red mx-auto max-w-[750px] rounded-[9px] bg-white p-2 text-center text-errorred " + className}>{children}</section>
}

export default Alert
