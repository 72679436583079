import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// name: {
		// robots: "noindex, nofollow",
		// },
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div>
			<RosaSection>
				<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
					<div className="item-center grid w-full grid-cols-1 justify-items-center">
						<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
					</div>
				</header>
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-blue">
					<h2 className="h2 mb-16 pt-4 text-center text-2xl">Teilnahme&shy;bedingungen</h2>
					<p className="mb-4">
						<strong>
							Teilnahmebedingungen für das Wilkinson Sword Hydro Playstation® 5 Gewinnspiel
							<br />
						</strong>
					</p>

					<h3 className="h3 py-4">1. Veranstalter</h3>
					<p className="mb-4">
						Die Wilkinson Sword GmbH, Schützenstraße 110, D-42659 Solingen (nachfolgend "Veranstalter" oder "Wilkinson Sword" genannt) veranstaltet im Zeitraum vom 02.01.2024 bis
						zum 31.03.2024, 23:59 Uhr, eine zeitlich begrenzte Gewinnspiel-Aktion mit Gewinnspiel für die Marke Wilkinson Sword (die "Aktion"). Die Aktion wird von der Magellan
						GmbH, Oststraße 10, 40211 Düsseldorf, Deutschland durchgeführt.
					</p>

					<h3 className="h3 py-4">2. Teilnahme</h3>
					<p className="mt-6 mb-2 font-bold">A. Allgemeines</p>
					<p className="mb-4">
						Teilnahmeberechtigt ist jede natürliche Person mit Wohnsitz in Deutschland, die zum Zeitpunkt der Teilnahme an der Aktion das achtzehnte (18.) Lebensjahr vollendet hat,
						unbeschränkt geschäftsfähig ist und einen qualifizierenden Einkauf wie unten beschrieben getätigt hat (nachfolgend Teilnehmer").
					</p>
					<p className="mb-4">
						Von der Teilnahme ausgeschlossen sind Mitarbeiter von Wilkinson Sword und der mit ihr verbundenen Unternehmen im Sinne von § 15 AktG und deren Angehörige sowie
						Mitarbeiter von Kooperationspartnern des Veranstalters, die an der Gestaltung oder Durchführung der Aktion beteiligt waren oder sind.
					</p>
					<p className="mb-4">
						Eine gewerbliche Teilnahme oder eine durch Dritte vermittelte Teilnahme (z.B. Gewinnspielservices, Makler oder Suchmaschinen) ist nicht zulässig. Darüber hinaus behält
						sich der Veranstalter das Recht vor, Personen von der Teilnahme auszuschließen, bei denen der Verdacht besteht, dass sie unerlaubte Hilfsmittel verwenden oder
						anderweitig versuchen, sich oder Dritten durch Manipulation einen Vorteil zu verschaffen (nach billigem Ermessen des Veranstalters). Der Veranstalter hat in solchen
						Fällen das Recht, die Auszahlung des Gewinns zu verweigern und/oder den Gewinn zurückzufordern.
					</p>
					<p className="mt-6 mb-2 font-bold">B. Teilnahmevoraussetzungen und Ablauf</p>
					<p className="mb-4">
						Um an der Aktion teilzunehmen, muss der Teilnehmer eines der Aktionsprodukte im angegebenen Einkaufszeitraum bei einem teilnehmenden Einzelhändler kaufen (auch
						Online-Käufe sind möglich).
					</p>
					<p className="mb-4">
						Die Teilnahme an der Aktion ist vom 02.01.2024 bis zum 31.03.2024, 23:59 Uhr, durch Hochladen des Rechnungsbelegs und Eingabe der erforderlichen Daten auf der
						Aktionsseite unter{" "}
						<a target="_blank" className="underline" href="https://www.gewinnspiel-hydro.de">
							www.gewinnspiel-hydro.de
						</a>{" "}
						(die "Aktionsseite") möglich. Der Scan oder das Foto des Original-Rechnungsbelegs muss das teilnehmende Produkt, das teilnehmende Geschäft, den Preis, die
						Rechnungsbelegnummer und das Kaufdatum deutlich erkennen lassen.
					</p>
					<p className="mb-4">
						Die Teilnahme an der Aktion ist kostenlos. Die Teilnehmer tragen lediglich ihre regulären Kosten für die von ihnen genutzten Kommunikationsmittel (z. B. Kosten für die
						Internetverbindung). Jeder Teilnehmer kann nur in seinem eigenen Namen teilnehmen. Eine Mehrfachteilnahme ist möglich. Pro Teilnahme darf nur ein Rechnungsbeleg
						eingereicht werden. Jeder Rechnungsbeleg darf nur einmal im Rahmen der Aktion verwendet werden.
					</p>
					<p className="mb-4">
						Die Teilnahme an der Aktion ist nur über das Formular auf der Aktionsseite möglich. Um teilzunehmen, muss der Teilnehmer das Formular während des angegebenen
						Teilnahmezeitraums mit gültigen, erforderlichen Daten (Name, Alter, Kaufinformationen und E-Mail-Adresse) ausfüllen und bestätigen. Jeder Teilnehmer ist für die
						Richtigkeit seiner Daten selbst verantwortlich. Die Datenschutzbestimmungen und Teilnahmebedingungen müssen akzeptiert werden. Der Teilnehmer hat dafür Sorge zu tragen,
						dass durch seine Teilnahme keine Ansprüche wegen Verletzung der Privatsphäre oder des allgemeinen Persönlichkeitsrechts entstehen, keine Rechte und/oder Interessen
						Dritter verletzt werden und keine Zahlungsansprüche jeglicher Art entstehen.
					</p>
					<p className="mb-4">Eine Kombination mit anderen Gutscheinen oder Promotionen ist nicht möglich. Der Gewinnanspruch ist nicht auf Dritte übertragbar.</p>
					<p className="mb-4">Der Original-Rechnungsbeleg ist als Kaufnachweis bis nach der Verlosung aufzubewahren und auf Verlangen vorzulegen.</p>
					<p className="mt-6 mb-2 font-bold">C. Kauf- und Teilnahmezeitraum</p>
					<p className="mb-4">
						Der Kaufzeitraum für die Aktionsprodukte ist vom 02.01.2024 bis zum 31.03.2024, um 23:59 Uhr (der "Kaufzeitraum"). Der Teilnahmezeitraum für den
						Rechnungseingangs-Upload ist vom 02.01.2024 bis zum 31.03.2024, 23:59 Uhr (der "Teilnahmezeitraum").
					</p>
					<p className="mt-6 mb-2 font-bold">D. Aktionsprodukte</p>
					<p className="mb-4">
						Die Aktion gilt für alle folgenden Produkte (nachfolgend die "Produkte"), die zwischen dem 02.01.2024 und dem 31.03.2024 um 23:59 Uhr bei einem teilnehmenden Händler in
						Deutschland oder online innerhalb Deutschlands ("Teilnehmender Händler") gekauft werden:
					</p>
					<p className="mb-2">Teilnehmende Aktionsprodukte inkl. GTIN</p>
					<ul className="mb-4 list-outside list-disc pl-6">
						<li>Hydro 5 Rasierapparat in Probiergröße (GTIN: 4027800979905)</li>
						<li>Hydro 3 Rasierapparat in Probiergröße (GTIN: 4027800479832)</li>
						<li>Hydro 5 Klinge 4+1 (GTIN: 4027800892266)</li>
						<li>Hydro 3 Klinge 4+1 (GTIN: 4027800402038)</li>
					</ul>
					<p>Teilnehmende Händler innerhalb Deutschlands:</p>
					<ul className="mb-4 list-outside list-disc pl-6">
						<li>Dirk Rossmann GmbH</li>
						<li>Müller Handels GmbH & Co. KG</li>
						<li>Penny Markt GmbH</li>
						<li>Kaufland Stiftung & Co. KG</li>
						<li>Edeka Handelsgesellschaft Nord mbH</li>
						<li>Bünting AG</li>
						<li>Globus SB-Warenhaus Holding GmbH & Co. KG</li>
						<li>Bartels-Langness Handelsgesellschaft mbH & Co. KG</li>
						<li>Georg Jos. Kaes GmbH</li>
					</ul>

					<h3 className="h3 py-4">3. Durchführung der Werbeaktion</h3>
					<p className="mb-4">
						Am Ende der Aktion werden zwanzig (20) Gewinner nach dem Zufallsprinzip aus allen bis zum Ende der Aktion eingegangenen gültigen Einsendungen gezogen. Unter allen
						Teilnehmern werden zwanzig (20) Sony Playstation® 5 Disk Edition verlost. Zehn (10) Sony Playstation® 5 sind exklusiv für Kunden des Händlers Dirk Rossmann GmbH
						reserviert. Der Wert eines jeden Preises beträgt 549,99€ UVP. Für den Fall, dass ein Preis nicht verfügbar ist, behält sich der Veranstalter das Recht vor, nach
						billigem Ermessen einen gleich- oder höherwertigen Preis zu vergeben.
					</p>
					<p className="mb-4">
						Der Veranstalter haftet für eigenes Verschulden und grobe Fahrlässigkeit, sowie das seiner gesetzlichen Vertreter, leitenden Angestellten oder sonstigen
						Erfüllungsgehilfen nach den gesetzlichen Bestimmungen. Gleiches gilt für die Übernahme von Garantien oder sonstiger verschuldensunabhängiger Haftung sowie für Ansprüche
						nach dem Produkthaftungsgesetz oder bei schuldhafter Verletzung von Leben, Körper oder Gesundheit.
					</p>
					<p className="mb-4">
						Der Veranstalter haftet für die eigene, die seiner Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen einfach fahrlässige Verletzung wesentlicher
						Vertragspflichten, d.h. solcher Pflichten, auf deren ordnungsgemäße Erfüllung die Teilnehmer bei der Durchführung des Wettbewerbs regelmäßig vertrauen dürfen; in diesem
						Fall ist die Haftung der Höhe nach auf den typischerweise vorhersehbaren Schaden begrenzt.
					</p>
					<p className="mb-4">Jede weitere Haftung des Veranstalters ist ausgeschlossen</p>

					<h3 className="h3 py-4">4. Auswahl der Gewinner, Benachrichtigung der Gewinner und Inanspruchnahme der Preise</h3>
					<p className="mb-4">
						Die Gewinner der Aktion werden innerhalb von 14 Tagen nach Ende des Teilnahmezeitraums per E-Mail benachrichtigt. Die Gewinner werden über die angegebenen Kontaktdaten
						kontaktiert und können aufgefordert werden, die Annahme des Preises zu bestätigen.
					</p>
					<p className="mb-4">
						Wenn ein Gewinner die Annahme des Preises nicht innerhalb von 14 Tagen bestätigt, verfällt der Preis, und ein anderer Gewinner wird nach dem Ermessen des Veranstalters
						aus dem ursprünglichen Pool der teilnahmeberechtigten Beiträge ausgewählt. Der Teilnehmer ist für die Richtigkeit der angegebenen Kontaktdaten verantwortlich. Kann der
						Gewinner aufgrund unvollständiger oder falscher Kontaktdaten nicht kontaktiert werden, verfällt der Preis, und ein anderer Gewinner wird gemäß den Bestimmungen dieser
						Klausel ausgewählt.
					</p>
					<p className="mb-4">
						Es ist nicht möglich, die Preise anders als festgelegt auszuzahlen, z. B. in bar, in Sachwerten, durch Umtausch oder Übertragung auf andere Personen. Ein Teilnehmer
						kann auf den Gewinn verzichten.
					</p>

					<h3 className="h3 py-4">5. Beendigung der Aktion</h3>
					<p className="mb-4">
						Die Aktion kann jederzeit ohne Vorankündigung und ohne Angabe von Gründen geändert, verlängert, vorübergehend ausgesetzt oder vorzeitig beendet werden. Hieraus ergeben
						sich keine Ansprüche gegen den Veranstalter. Dies kann insbesondere dann der Fall sein, wenn Fehlfunktionen, Fälschungen, Viren oder ähnliche Schäden auftreten oder ein
						Fall von Missbrauch der Gewinnspiele vorliegt und die Verwaltung, Sicherheit, Fairness, Integrität oder der Ablauf der Aktion beeinträchtigt wird. Aus einer vorzeitigen
						Beendigung können keine Ansprüche abgeleitet werden.
					</p>
					<h3 className="h3 py-4">6. Sonstiges</h3>
					<p className="mb-4">Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen Warenkauf.</p>
					<p className="mb-4">
						Sollten einzelne Bestimmungen der Teilnahmebedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Teilnahmebedingungen unberührt. An ihre Stelle
						tritt diejenige gültige Bestimmung, die dem Zweck der unwirksamen Bestimmung am nächsten kommt.
					</p>
					<p className="mb-4">
						Wilkinson Sword behält sich das Recht vor, diese Teilnahmebedingungen jederzeit und ohne vorherige Ankündigung zu ändern. Dies gilt auch für die vorzeitige Beendigung
						der Aktion. Die Änderung tritt mit der Veröffentlichung der geänderten Teilnahmebedingungen auf{" "}
						<a target="_blank" className="underline" href="https://www.gewinnspiel-hydro.de">
							www.gewinnspiel-hydro.de
						</a>{" "}
						in Kraft. Die Teilnahmebedingungen sind für die Dauer der Aktion gültig. Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. Alle Fragen oder Kommentare
						zur Aktion sind per E-Mail an{" "}
						<a target="_blank" className="underline" href="mailto:info@gewinnspiel-hydro.de">
							info@gewinnspiel-hydro.de
						</a>{" "}
						zu richten.
					</p>

					<h3 className="h3 py-4">7. Datenschutz</h3>
					<p className="mb-4">
						Die nachfolgenden Datenschutzhinweise informieren die Teilnehmer über Art, Umfang und Zweck der Erhebung und Verwendung ihrer personenbezogenen Daten im Rahmen der
						Aktion durch den Veranstalter als datenschutzrechtlich Verantwortlichem. Mit der Teilnahme an der Aktion und der Erteilung der Einwilligung willigt der Teilnehmer ein,
						dass Wilkinson Sword seine personenbezogenen Daten (Name, Geburtsdatum, E-Mail-Adresse und Postanschrift) speichern und verarbeiten darf.
					</p>
					<p className="mb-4">Eine Weitergabe von Daten an Dritte erfolgt nur, wenn dies für die Abwicklung der Aktion erforderlich und gesetzlich zulässig ist.</p>
					<p className="mb-4">
						Im Rahmen der Aktion werden folgende personenbezogene Daten der Teilnehmer von der Magellan GmbH erhoben und verarbeitet und für die Teilnahme, Durchführung und
						Abwicklung der Gewinnspiele, einschließlich der Zustellung der Preise und ggf. der Veröffentlichung des Vornamens des Gewinners, nach Maßgabe dieser
						Teilnahmebedingungen und auf Grundlage einer Einwilligung verwendet.
					</p>
					<p className="mb-4">Die Verarbeitung der personenbezogenen Daten des Teilnehmers erfolgt zum Zweck der Durchführung und Abwicklung</p>
					<p className="mb-4">- der Aktion: Vor- und Nachname, Geburtsdatum, E-Mail-Adresse, Postanschrift.</p>
					<p className="mb-4">
						Anschließend werden die oben genannten personenbezogenen Daten zu Werbezwecken verwendet, wenn der Teilnehmer dem Erhalt von Werbemitteilungen zugestimmt hat. Nach
						Beendigung der Aktion wird der Veranstalter die personenbezogenen Daten der Teilnehmer, die dem Erhalt von Werbeinformationen nicht zugestimmt haben, bis zum 30. April
						2024 löschen.
					</p>
					<p className="mb-4">
						Weitere Informationen darüber, wie der Veranstalter personenbezogene Daten verarbeitet, einschließlich Informationen über die Rechte der Teilnehmer, finden Sie unter:{" "}
						<a target="_blank" className="underline" href="https://wilkinsonsword.de/policies/privacy-policy">
							https://wilkinsonsword.de/policies/privacy-policy
						</a>
						. Die Teilnehmer haben das Recht, jederzeit Auskunft über die Verarbeitung ihrer personenbezogenen Daten zu erhalten, die Berichtigung, Löschung oder Einschränkung der
						Verarbeitung ihrer personenbezogenen Daten zu verlangen und die zur Verfügung gestellten personenbezogenen Daten in einem strukturierten, allgemein gebräuchlichen und
						maschinenlesbaren Format zu erhalten und an einen anderen für die Verarbeitung Verantwortlichen zu übermitteln. Die Teilnehmer haben das Recht, ihre Einwilligung zu
						widerrufen, wenn der Veranstalter personenbezogene Daten auf der Grundlage ihrer Einwilligung verarbeitet. Zu diesem Zweck können sich die Teilnehmer an die folgende
						Adresse wenden: Wilkinson Sword GmbH, Schützenstraße 110, 42659 Solingen. Die Bitte um Auskunft und Widerruf kann der Teilnehmer per E-Mail an den Veranstalter unter{" "}
						<a target="_blank" className="underline" href="mailto:info@gewinnspiel-hydro.de">
							info@gewinnspiel-hydro.de
						</a>{" "}
						richten. Im Übrigen gelten die allgemeinen Datenschutzrichtlinien des Veranstalters.
					</p>
				</div>
			</RosaSection>
			<div className="text-red bg-tuerkis py-10 pt-5 text-center text-xl">
				<button onClick={() => navigate(-1)} className="btn btn-hero mt-8 rounded bg-blue px-12 py-4 text-white">
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Terms
