const PinkSection = ({ children }) => {
	return (
		<section>
			<div className="wrap relative mx-auto max-w-[1400px] bg-blue" style={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/sectionMiddle.png")' }}>
				{children}
			</div>
		</section>
	)
}

export default PinkSection
