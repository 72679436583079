import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import MobileMenu from "./MobileMenu"

const Header = ({ isPromoOver = false, showPrescreen = false }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>
			<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
				<div className="item-center grid w-full grid-cols-1 justify-items-center">
					<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
				</div>
				<div className="grid h-[235px] grid-cols-[20%_80%] md:h-[400px] lg:h-[620px]">
					<div className="pl-12">
						<img
							src={process.env.PUBLIC_URL + "/images/knossi/JensKnosallaFull.png"}
							className="absolute z-[100] max-h-[450px] -translate-y-[30px] -translate-x-[25px] md:max-h-[80%] lg:max-h-[80%] lg:-translate-y-[80px] lg:translate-x-[5px] xl:max-h-[230%] "
						></img>
					</div>
					<div>
						<p className="text-stroke mt-2 w-full -rotate-[12deg] text-center text-[20px] font-bold  uppercase  text-yellow md:text-[35px] lg:text-[50px] xl:text-[60px]">
							Next Level Rasur
						</p>
						<div className=" lg:pr-26 l:pr-44 w-full translate-x-[45px] -rotate-[12deg] bg-yellow py-0 pt-2 pb-0 pr-[20%] text-right md:pr-32 lg:translate-x-[54px] lg:py-2">
							<p className="mb-0 font-Gotham text-[1.4rem] uppercase leading-[18px] text-blue md:text-[42px] md:leading-[42px] lg:mr-[30px] lg:text-[52px] lg:leading-[72px]">
								Unschlagbar
							</p>
							<p className="mt-0 font-Gotham text-[2.7rem] uppercase leading-[63px] text-blue md:text-[92px]  md:leading-[100px] lg:text-[120px] lg:leading-[125px] xl:text-[150px]">
								Günstig
							</p>
						</div>
						<p className="text-stroke mt-[10px] -ml-[48px] w-full translate-x-[77px] -rotate-[12deg] text-center text-[18px] font-bold uppercase text-yellow md:text-[24px] lg:-ml-12 lg:mt-0 lg:translate-x-[160px]  lg:text-[46px] xl:ml-0  xl:text-[60px]">
							Jetzt zum Testpreis
						</p>
					</div>
				</div>
			</header>
			<div className="mx-auto max-w-[1400px]">
				<section className="border-pink-after relative top-0 z-10 mx-auto grid h-[230px] grid-cols-1 bg-pink  pb-44 lg:h-[380px] lg:grid-cols-[20%_60%_30%] lg:pb-0 xl:grid-cols-[30%_50%_30%]">
					<div></div>
					<img className="absolute -left-[20px] top-[134px] z-[200] hidden h-[167px] w-[47px] lg:block" src={process.env.PUBLIC_URL + "/images/pink-border-left.png"} />
					<div className="px-12">
						<p className="mb-4 ml-[40%] pt-4 text-center font-Gotham  text-[32px] uppercase text-white md:text-[44px] lg:ml-[30px]  lg:pt-[30px] lg:text-[62px] xl:text-[62px]">
							{" "}
							Gewinne <br />1 von 10
						</p>
						<img
							className="ml-24 mb-8 w-[200px] text-center md:ml-[24rem] lg:ml-24  lg:w-[400px] lg:-translate-x-[30px] xl:w-[500px] xl:-translate-x-[70px]"
							src={process.env.PUBLIC_URL + "/images/knossi/ps5_logo.png"}
						/>
					</div>
					<div className="hidden lg:block">
						<img
							className="row-span-1 mb-8 ml-7 max-w-[34%] text-center lg:ml-24 lg:max-w-[90%] lg:-translate-x-[210px] lg:-translate-y-[190px]"
							src={process.env.PUBLIC_URL + "/images/knossi/playstaion5-full.png"}
						/>
					</div>
				</section>
			</div>
		</>
	)
}

export default Header
