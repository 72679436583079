import { useEffect } from "react"
import RosaSection from "../components/elements/BeigeSection"
import { Link } from "react-router-dom"

function PreScreen() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<RosaSection>
			<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
				<div className="item-center grid w-full grid-cols-1 justify-items-center">
					<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
				</div>
				<div className="grid h-[296px] grid-cols-[15%_85%] overflow-hidden md:h-[400px] md:grid-cols-[30%_70%] lg:h-[620px]">
					<div className="overflow-hidden pl-12">
						<img
							src={process.env.PUBLIC_URL + "/images/knossi/JensKnosallaFull.png"}
							className="absolute 
							z-[0] max-h-[450px] -translate-y-[30px] -translate-x-[45px] md:max-h-[230%] lg:-translate-y-[80px] lg:translate-x-[5px] "
						></img>
					</div>
					<div className=" mx-auto mt-12 max-w-[275px] pl-16 text-center md:mt-24 md:max-w-[485px] lg:max-w-[885px] lg:pr-6 lg:pl-44">
						{" "}
						<h2 className="mb-8 text-[18px] font-bold uppercase leading-[18px] text-blue md:text-[32px] md:leading-[32px] lg:text-[52px] lg:leading-[51px]">
							Das Playstation<p className=" -mt-[5px] inline-block align-top text-[12px] md:text-[22px] lg:-mt-[20px]">&#174;</p>5 GEWINNSPIEL <br /> Beginnt bald Hier!
						</h2>
						<p className="mb-4 text-[14px] text-blue md:text-[20px] lg:text-[28px]">
							Ab dem 02.01.2024 kannst du hier 1 von 10 PlayStation<p className=" -mt-[2px] inline-block align-top text-[8px] lg:text-[14px]">&#174;</p>5-Konsolen gewinnen. Weitere
							Informationen findest du in unseren{" "}
							<Link to={process.env.PUBLIC_URL + "/teilnahmebedingungen"} className="underline" onClick={() => track("Teilnahmebedingungen")}>
								Teilnahmebedingungen
							</Link>
							.
						</p>
					</div>
				</div>
			</header>
			<div className="text-red relative z-0 px-4 pt-0 pb-16 text-center text-xl"></div>
		</RosaSection>
	)
}
export default PreScreen
