import { Link } from "react-router-dom"
import React from "react"

function Footer() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<footer className="relative mx-auto flex max-w-[1400px] flex-col  items-center  bg-yellow lg:z-[600]">
			<section className="bg-green max-w-[990px] px-2 pt-16 lg:px-0">
				<div className="text-center">
					<h2 className="font-Gotham text-[24px] font-bold uppercase leading-[24px] text-blue lg:text-[58px]  lg:leading-[58px]">
						Lust auf weitere spannende Neuigkeiten zu Wilkinson?
					</h2>
					<p className="mt-8 font-GothamMedium text-[20px] font-bold text-blue lg:text-3xl">Dann folge uns doch auf Instagram und TikTok </p>
				</div>
				<div className="align-self-center mx-auto max-w-[140px] justify-center self-center  pt-8 md:max-w-[200px]">
					<a
						href="https://www.instagram.com/wilkinson_men/"
						target="_blank"
						className="inline-block pr-8 md:max-w-[116px] lg:pt-0"
						rel="noreferrer"
						onClick={() => track("Instagram")}
					>
						<img src={"/images/knossi/instagram.png"} alt="Instagram" width="116" height="116" className="h-auto max-w-[50px] flex-shrink-0 md:max-w-[60px]" />
					</a>

					<a href="https://www.tiktok.com/@wilkinsonsword_de" className="inline-block" target="_blank" rel="noreferrer" onClick={() => track("TikTok")}>
						<img src={"/images/knossi/tiktok.png"} alt="Tiktok" width="116" height="116" className="h-auto max-w-[50px] md:max-w-[60px]" />
					</a>
				</div>{" "}
				<p className="mt-6 mb-6 text-center font-GothamMedium text-[20px] font-bold text-blue lg:text-3xl">
					oder besuche unsere Webseite{" "}
					<a href="https://wilkinsonsword.de" className="underline" target="_blank">
						www.wilkinsonsword.de
					</a>
				</p>
				<div className="mt-8 flex flex-col items-center justify-center border-t-2 border-blue py-8 lg:mt-0 lg:flex-row">
					<Link to={"/teilnahmebedingungen"} className="text-base text-blue" onClick={() => track("Teilnahmebedingungen")}>
						Teilnahmebedingungen
					</Link>
					<span className="hidden px-4 text-base text-blue lg:block">|</span>
					<Link to={"/faq"} className="text-base text-blue" onClick={() => track("FAQ")}>
						FAQ
					</Link>
					<span className="hidden px-4 text-base text-blue lg:block">|</span>
					<a href="https://wilkinsonsword.de/policies/privacy-policy" target="_blank" className="text-base text-blue">
						Datenschutzerklärung
					</a>
					<span className="hidden px-4 text-base text-blue lg:block">|</span>
					<Link to={"/kontakt"} className="text-base text-blue" onClick={() => track("Kontakt")}>
						Kontakt
					</Link>
					<span className="hidden px-4 text-base text-blue lg:block">|</span>
					<Link to={"/impressum"} className="text-base text-blue" onClick={() => track("Impressum")}>
						Impressum
					</Link>
				</div>
			</section>
		</footer>
	)
}

export default Footer
