import { useAutoAnimate } from "@formkit/auto-animate/react"
import axios from "axios"
import { useState } from "react"
import Dropzone from "react-dropzone"
import { ERRORTYPES } from "../../config"
import { getEnvConfig } from "../../env"
import convertPDF2JPG from "../convertPDF/PDF2JPG"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"

interface ReceiptUploadProps {
	dispatch: Function
}
const ReceiptUpload: React.FC<ReceiptUploadProps> = ({ dispatch }) => {
	const env = getEnvConfig()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function onDrop(dropedFiles: any) {
		setLoading(true)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			} else {
				setError(null)

				setAcceptedFile(dropedFiles[0]) // TODO: check if this needs to be holded in the state???
				handleFileType(dropedFiles[0])
				setLoading(true)
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
			} catch (e) {}
		}
	}

	function handleFileTypeError(error: any) {
		try {
			if (error.response.data.errors.errors[0] === "Participation with same receipt already exists.") {
				setError(ERRORTYPES.DOUBBLED)
			} else if (error.response.data.errors.errors[0] === "Reached maximum attempts with uploaded receipt.") {
				setError(ERRORTYPES.MAXTRIES)
			} else {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
			}
		} catch (error) {
			setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
		}

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
		} catch (e) {}

		setLoading(false)
		setAcceptedFile(null)
	}

	function handleFileType(file) {
		setLoading(true)
		if (file.type !== "application/pdf") {
			uploadReceipt(file)
		} else {
			/* ✨ PDF magic ✨ */
			convertPDF2JPG(file)
				.then(file => {
					uploadReceipt(file)
				})
				.catch(error => {
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					setLoading(false)
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				})
		}
	}

	async function uploadReceipt(file: any) {
		const env = getEnvConfig()
		const formdata = new FormData()
		formdata.append("file", file)
		axios
			.post(env.apibase + "uploadReceipt.php?cv=" + Date.now(), formdata, {
				headers: {
					"content-type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then(res => {
				if (res.data.enqueued) {
					console.log("Suc", res.data)
					checkOCRStatus(res.data.temporaryReceiptDataId)
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "receipt", "receipt-upload": "upload-successful" })
					} catch (e) {}
				} else {
					console.log("NoSuc", env.apibase, res.data)
					handleFileTypeError(new Error("File submitted but something's wrong 🤔: " + JSON.stringify(res)))
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				}
			})
			.catch(error => {
				console.log("Err", error)
				handleFileTypeError(error)
				setAcceptedFile(null)
			})
	}

	async function checkOCRStatus(reference: string, maxTries: number = 10) {
		axios
			.get(env.apibase + "checkReceiptUploadStatus.php?reference=" + reference + "&cv=" + Date.now())
			.then(res => {
				if (!!res.data.classificationPassed) {
					// Process succeeded

					if (res.data.classificationPassed) {
						dispatch({
							type: "SET_RECEIPT_REFERENCE",
							receiptReference: reference,
							receiptFileName: res.data.processedFileName,
						})
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": true })
						} catch (e) {}
					} else {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setAcceptedFile(null)
					}

					setLoading(false)
				} else {
					maxTries--
					if (maxTries === 0) {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setLoading(false)

						console.log(new Error("Too many attempts!"))
						setAcceptedFile(null)

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
						} catch (e) {}
					} else {
						setTimeout(() => checkOCRStatus(reference, maxTries), 3000)
					}
				}
			})
			.catch(error => {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
				setAcceptedFile(null)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			})
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
			{({ getRootProps, getInputProps }) => (
				<section ref={receiptUploadRef} className="lg:mx-8">
					{!loading ? (
						<div className="flex flex-col items-center" {...getRootProps()}>
							<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />

							{acceptedFile ? (
								<>
									<div className="relative">
										<span className="text-green px- block w-[100%] rounded bg-white py-0 px-16 text-center text-[42px] uppercase text-tuerkis lg:inline-block lg:w-[auto] lg:text-[50px]">
											<strong className="">Upload</strong>
											<img src={process.env.PUBLIC_URL + "/images/ico-uploaded.svg"} alt="" className="ml-4 -mt-[10px] inline-block h-[40px] w-[40px] flex-shrink-0 flex-grow-0" />
										</span>
									</div>
								</>
							) : (
								<div className="relative">
									{error ? (
										<>
											<span className="text-green px- block w-[100%] rounded bg-white py-0 px-16 text-center text-[42px] uppercase text-errorred lg:inline-block lg:w-[auto] lg:text-[50px]">
												<strong className="">Upload</strong>
												<img
													src={process.env.PUBLIC_URL + "/images/upload_error.png"}
													alt=""
													className="ml-4 -mt-[10px] inline-block h-[40px] w-[40px] flex-shrink-0 flex-grow-0"
												/>
											</span>
										</>
									) : (
										<>
											<span className="text-green px- block w-[100%] rounded bg-white py-0 px-16 text-center text-[42px] uppercase  text-blue lg:inline-block lg:w-[auto] lg:text-[50px]">
												<strong>Upload</strong>
											</span>
										</>
									)}
								</div>
							)}
							<div className="mx-auto max-w-[380px] pb-8 text-center">
								<>
									<div className="mt-6 text-center text-[14px] text-white">
										<p>
											<strong>Folgendes muss ersichtlich sein:</strong>
											<br /> Kaufdatum, Einkaufsstätte, Artikel inkl. Kaufpreis, Rechnungsbeleg, Rechnungsbeleg-Nummer. <br />
											Maximale Dateigröße 8 MB (Formate: JPG, PNG, PDF). <br />
											Jeder Rechnungsbeleg kann nur einmal genutzt werden.
										</p>
									</div>
								</>
							</div>
						</div>
					) : (
						<div className="flex flex-col items-center" {...getRootProps()}>
							{" "}
							<span className="text-green px- block w-[100%] rounded bg-white py-0 px-16 text-center text-[42px] uppercase  text-blue lg:inline-block lg:w-[auto] lg:text-[50px]">
								<strong>
									<Spinner />
								</strong>
							</span>
							<div className="mx-auto max-w-[380px] pb-8 text-center">
								<>
									<div className="mt-6 text-center text-[14px] text-white">
										<p>
											<strong>Folgendes muss ersichtlich sein:</strong>
											<br /> Kaufdatum, Einkaufsstätte, Artikel inkl. Kaufpreis, Rechnungsbeleg, Rechnungsbeleg-Nummer. <br /> Maximale Dateigröße 8 MB (Formate: JPG, PNG, PDF).{" "}
											<br />
											Jeder Rechnungsbeleg kann nur einmal genutzt werden.
										</p>
									</div>
								</>
							</div>
						</div>
					)}
					{error && <Alert className={"mt-8 text-left"}>{error}</Alert>}
				</section>
			)}
		</Dropzone>
	)
}

export default ReceiptUpload
