const b64toBlob = async b64 => {
	return new Promise((resolve, reject) => {
		var img = new Image()
		img.onerror = error => reject(error)
		img.onload = () => {
			let canvas = document.createElement("canvas")
			canvas.width = img.width
			canvas.height = img.height

			var ctx = canvas.getContext("2d")
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

			canvas.toBlob(blob => {
				resolve(blob)
			})
		}
		img.src = b64
	})
}
export default b64toBlob
