import { useAutoAnimate } from "@formkit/auto-animate/react"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: boolean
}

const ElemCheckbox: React.FC<CheckboxProps> = ({ className, props, label, errMsg, hasError }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block cursor-pointer " + (className || "")}>
			<div className="flex">
				<input className={"peer absolute hidden h-1 w-1 "} {...props} />
				<span className="block h-[32px] w-[32px] flex-shrink-0 rounded bg-white peer-checked:hidden"></span>
				<span className="block flex hidden h-[32px] w-[32px] flex-shrink-0 items-center justify-center rounded bg-white peer-checked:flex">
					<img src={process.env.PUBLIC_URL + "/images/Check.svg"} alt="check" className="w-[24px]" />
				</span>
				<div>
					<div className="pl-4 text-white">{label}</div>
					<div ref={animationRef}>{hasError && <div className="formErrorMsg pl-4 pt-2 font-bold text-pink">{errMsg}</div>}</div>
				</div>
			</div>
		</label>
	)
}

export default ElemCheckbox
