const GreenSection = ({ children }) => {
	return (
		<section>
			<img src={process.env.PUBLIC_URL + "/images/sectionTop.png"} alt="" className="w-full drop-shadow-[0_0_50px_rgba(242,161,3,1)]" />
			<div className="relative bg-green bg-[length:100%_auto] bg-repeat-y " style={{ backgroundImage: 'url("' + process.env.PUBLIC_URL + '/images/sectionMiddle.png")' }}>
				{children}
			</div>
			<img src={process.env.PUBLIC_URL + "/images/sectionBottom.png"} alt="" className="w-full" />
		</section>
	)
}

export default GreenSection
