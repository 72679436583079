import React, { useEffect } from "react"
import RosaSection from "../components/elements/BeigeSection"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
				<div className="item-center grid w-full grid-cols-1 justify-items-center">
					<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
				</div>
				<div className="grid h-[380px] grid-cols-[20%_80%] md:h-[400px] lg:h-[620px]">
					<div className="pl-12">
						<img
							src={process.env.PUBLIC_URL + "/images/knossi/JensKnosallaFull.png"}
							className="absolute z-[100] max-h-[450px] -translate-y-[30px] -translate-x-[25px] md:max-h-[80%] lg:max-h-[80%] lg:-translate-y-[80px] lg:translate-x-[5px] xl:max-h-[230%] "
						></img>
					</div>
					<div className=" mx-auto mt-24 max-w-[275px] pl-20 text-center lg:max-w-[830px] lg:pr-6 lg:pl-44">
						{" "}
						<h2 className="mb-8 text-[18px] uppercase text-white lg:text-[52px]">Hier ist etwas schief gelaufen...</h2>
						<div className="py-10 pt-5 text-center text-white">
							<a href="/" className="btn btn-hero mt-8 inline-block rounded bg-blue  px-4 py-2">
								Zurück zur Aktion
							</a>
						</div>
					</div>
				</div>
			</header>
			<div className="text-red relative z-0 px-4 pt-0 pb-16 text-center text-xl"></div>
		</>
	)
}
export default Error
