import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { Link, useNavigate } from "react-router-dom"
import GreenSection from "../components/elements/GreenSection"
import RosaSection from "../components/elements/BeigeSection"

const Contact: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<RosaSection>
				<header className="mx-auto  w-full max-w-[1400px] overflow-hidden bg-[url('/src/bgimages/header_bg.png')] bg-cover bg-[center_top] bg-no-repeat lg:bg-cover">
					<div className="item-center grid w-full grid-cols-1 justify-items-center">
						<img src={process.env.PUBLIC_URL + "/images/knossi/wilkinson_hydro_logo.png"} className="item-center w-[100px] self-center lg:w-[247px]" />
					</div>
				</header>
				<div className="container mx-auto mt-6 max-w-[400px] py-8 px-4 text-center text-blue ">
					<h2 className="h2 mb-8 text-2xl">Kontakt</h2>
					<p className=" mb-4">Bei Fragen oder Anmerkungen zur Aktion erreichst du uns per E-Mail unter:</p>
					<p className=" mb-4">
						<svg className="mr-2 inline-block w-5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EmailIcon">
							<path fill="#ffffff" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
						</svg>
						<a
							href="mailto:info@gewinnspiel-hydro.de"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "mail" })
								} catch (e) {}
							}}
						>
							info@gewinnspiel-hydro.de
						</a>
					</p>
					<p className=" mb-4">
						Allgemeine Informationen zur Aktion findest Du außerdem in den{" "}
						<Link
							to="/faq"
							target={"_blank"}
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "faq" })
								} catch (e) {}
							}}
						>
							<span className="underline">FAQ</span>
						</Link>
						.
					</p>
				</div>
			</RosaSection>
			<div className="text-red py-10 pt-5 text-center text-xl">
				<button onClick={() => navigate(-1)} className="btn btn-hero  mt-8 rounded bg-blue px-12 py-4 text-white">
					Zurück
				</button>
			</div>
		</DocumentMeta>
	)
}
export default Contact
